.testimonials-carousel {
    padding: 10%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-content: center;
    align-content: center;
    background-color: var(--ezClockerNavy);
    border-top: solid 10px #FF9900;

    .testimonial-content {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        align-content: center;
        align-content: center;

        .testimonial-text-container {
            padding: 10px;
            color: var(--ezClockerBlack);
            background-color: var(--ezClockerOrange);
            text-align: center;
            border-radius: 3px;
            box-shadow: 0px 0px 12px 0px var(--ezClockerBlack);
        }
    }

    .testimonial-first-paragraph {
        font-size: 20pt;
    }

    .testimonial-second-paragraph {
        font-weight: bold;
        font-size: 18pt;
    }

    .testimonial-third-paragraph {
        font-size: 16pt;
    }


    .rect-div {
        display: flex;
        justify-content: center;
    }

    .slide {
        display: none;
    }

    .active-anim p {
        animation: fade 3s forwards;
    }

    @keyframes fade {
        from {
            opacity: 0;
        }

        to {
            opacity: 2;
        }
    }
    @keyframes fade {
        from {
            opacity: 0;
        }

        to {
            opacity: 2;
        }
    }
    @keyframes fade {
        from {
            opacity: 0;
        }

        to {
            opacity: 2;
        }
    }
    @keyframes fade {
        from {
            opacity: 0;
        }

        to {
            opacity: 2;
        }
    }

    .rect {
        flex: unset;
        margin-top: 40px;
        margin-left: 5px;
        margin-right: 5px;
        height: 10px;
        width: 50px;
        display: inline-block;
        cursor: pointer;
        border-radius: 3px;
    }
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {
    .testimonials-carousel {
        padding: 20px;

        .testimonial-first-paragraph {
            font-size: 16pt;
        }

        .testimonial-second-paragraph {
            font-weight: bold;
            font-size: 16pt;
        }

        .testimonial-third-paragraph {
            font-size: 12pt;
        }
    }
}


/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    .testimonials-carousel {
        padding: 20px;

        .testimonial-first-paragraph {
            font-size: 16pt;
        }

        .testimonial-second-paragraph {
            font-weight: bold;
            font-size: 16pt;
        }

        .testimonial-third-paragraph {
            font-size: 12pt;
        }
    }
}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {
    .testimonials-carousel {
        .testimonial-first-paragraph {
            font-size: 16pt;
        }

        .testimonial-second-paragraph {
            font-weight: bold;
            font-size: 16pt;
        }

        .testimonial-third-paragraph {
            font-size: 12pt;
        }
    }
}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1200px) {}

/* Special Laptops devices (laptops/desktops, 1200px and up) */
@media (min-width: 1300px) and (max-width: 1440px) {}

