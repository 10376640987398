.teams-section {
    display: grid;
    padding: 20px;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    border-top: solid 10px #FF9900;

    .teams-section-header {
        color: var(--ezClockerNavy);
        text-align: center;
    }

    .teams-content {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        grid-template-rows: auto;
        column-gap: 20px;
        row-gap: 20px;
        align-content: center;
        justify-content: space-evenly;

        .team-card {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            align-content: center;
            justify-content: center;

            .team-image {
                width: 200px;
            }
        }
    }
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {
    .teams-section {
        .teams-content {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            column-gap: 20px;
            row-gap: 20px;
            align-content: center;
            justify-content: space-evenly;

            .team-card {
                .team-image {
                    width: 100px;
                }
            }
        }
    }
}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    .teams-section {
        .teams-section-header {
            color: var(--ezClockerNavy);
            text-align: center;
            font-size: 18pt;
            margin-bottom: 8px;
            margin-top: 8px;
        }

        .teams-content {
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto;
            column-gap: 20px;
            row-gap: 20px;
            align-content: center;
            justify-content: space-evenly;

            .team-card {
                .team-image {
                    width: 100px;
                }
            }
        }
    }
}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {
    .teams-section {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        column-gap: 20px;
        row-gap: 20px;
        align-content: center;
        justify-content: space-evenly;

        .teams-content {
            .team-card {
                .team-image {
                    width: 75px;
                }
            }
        }
    }
}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {
    .teams-section {
        .teams-content {
            .team-card {
                .team-image {
                    width: 100px;
                }
            }
        }
    }
}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {
    .teams-section {
        .teams-content {
            .team-card {
                .team-image {
                    width: 100px;
                }
            }
        }
    }
}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1024px) and (max-width:1200px) {
    .teams-section {
        display: grid;
        padding: 20px;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        border-top: solid 10px #FF9900;

        .teams-section-header {
            color: var(--ezClockerNavy);
            text-align: center;
        }

        .teams-content {
            display: grid;
            grid-template-columns: auto auto auto;
            grid-template-rows: auto auto;
            column-gap: 20px;
            row-gap: 20px;
            align-content: center;
            justify-content: space-evenly;

            .team-card {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: center;

                .team-image {
                    width: 200px;
                }
            }
        }
    }
}

/* Special Laptops devices (laptops/desktops, 1200px and up) */
@media (min-width: 1300px) and (max-width: 1440px) {
     .teams-section {
        display: grid;
        padding: 20px;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        border-top: solid 10px #FF9900;

        .teams-section-header {
            color: var(--ezClockerNavy);
            text-align: center;
        }

        .teams-content {
            display: grid;
            grid-template-columns: auto auto auto auto auto auto;
            grid-template-rows: auto;
            column-gap: 20px;
            row-gap: 20px;
            align-content: center;
            justify-content: space-evenly;

            .team-card {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: center;

                .team-image {
                    width: 150px;
                }
            }
        }
    }
}
