.meet-our-customers-main-div {
    width: 100%;
    text-align: center;
    padding: 0 0 70px;

    .meet-our-customers-main-head {
        font-size: 30px;
        padding: 30px;
    }

    .customerImageCarousel4 {
        justify-content: center;
    }

    .customerImageCarousel2 {
        display: none;
    }

    .customerImageCarousel1 {
        display: none;
    }
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {
    .meet-our-customers-main-div {
        .customerImageCarousel4 {
            display: none;
        }

        .customerImageCarousel1 {
            display: block;
        }
    }
}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    .meet-our-customers-main-div {
        .customerImageCarousel4 {
            display: none;
        }

        .customerImageCarousel1 {
            display: block;
        }
    }
}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {
    .meet-our-customers-main-div {
        .customerImageCarousel4 {
            display: none;
        }

        .customerImageCarousel2 {
            display: block;
        }
    }
}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {
    .meet-our-customers-main-div {
        .customerImageCarousel4 {
            display: none;
        }

        .customerImageCarousel2 {
            display: block;
        }
    }
}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1200px) {}
