.devices-section {
    border-color: var(--ezClockerOrange);
    border-style: none;
    border-top-style: solid;
    border-top-width: 10px;

    .employee-time-tracking-options-text {
        padding: 30px;
        text-align: center;
        font-weight: bold;
        font-size: 24px;
    }

    .ezclocker-device-sections-div {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: auto;
        grid-row-gap: 20px;
        padding: 20px;

        .ezclocker-device-section {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto auto auto;
            align-content: start;
            justify-content: center;

            .device-image-container {
                text-align: center;

                .device-web-image {
                    width: 400px;
                }

                .device-mobile-image {
                    width: 125px;
                }

                .device-kiosk-image {
                    width: 350px;
                }
            }

            .ezclocker-device-header {
                color: var(--ezClockerNavy);
                font-size: 24px;
                text-align: center;
            }

            .ezclocker-device-main-description-div {
                text-align: center;
                font-size: 16pt;
            }

            .devices-learn-more-button-container {
                text-align: center;

                .devices-learn-more-button {
                    width: 200px;
                    background-color: var(--ezClockerOrange);
                    color: var(--ezBlack);
                    font-weight: bold;
                    font-size: 16pt;
                    cursor: pointer;
                }
            }

            .devices-learn-more-button:hover {
                background-color: var(--ezHoverOrange);
            }

            .devices-learn-more-button:active {
                background-color: var(--ezDarkOrange);
            }

            .devices-learn-more-button:disabled {
                background-color: var(--ezBorderOrange);
                border-color: var(--ezDarkOrange);
                color: var(--ezClockerOrange);
            }
        }
    }
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {
    .devices-section {

        .ezclocker-device-sections-div {
            grid-template-columns: auto;
            grid-template-rows: auto auto auto;
            align-content: center;
            justify-content: space-evenly;

            .ezclocker-device-section {

                .device-image-container {

                    .device-web-image {
                        width: auto;
                        height: 150px;
                    }

                    .device-mobile-image {
                        width: auto;
                        height: 150px;
                    }

                    .device-kiosk-image {
                        width: auto;
                        height: 150px;
                    }
                }

                .ezclocker-device-header {
                    margin-bottom: 8px;
                }

                .ezclocker-device-main-description-div {
                    margin-bottom: 8px;
                    margin-top: 8px;
                }

                .devices-learn-more-button-container {
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
            }
        }
    }
}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    .devices-section {

        .ezclocker-device-sections-div {
            grid-template-columns: auto;
            grid-template-rows: auto auto auto;
            align-content: center;
            justify-content: space-evenly;

            .ezclocker-device-section {

                .device-image-container {

                    .device-web-image {
                        width: auto;
                        height: 150px;
                    }

                    .device-mobile-image {
                        width: auto;
                        height: 150px;
                    }

                    .device-kiosk-image {
                        width: auto;
                        height: 150px;
                    }
                }

                .ezclocker-device-header {
                    margin-bottom: 8px;
                }

                .ezclocker-device-main-description-div {
                    margin-bottom: 8px;
                    margin-top: 8px;
                }

                .devices-learn-more-button-container {
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
            }
        }
    }
}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {
    .devices-section {

        .ezclocker-device-sections-div {
            align-content: center;
            justify-content: space-evenly;

            .ezclocker-device-section {

                .device-image-container {
                    text-align: center;

                    .device-web-image {
                        width: auto;
                        height: 150px;
                    }

                    .device-mobile-image {
                        width: auto;
                        height: 150px;
                    }

                    .device-kiosk-image {
                        width: auto;
                        height: 150px;
                    }
                }
            }
        }
    }
}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1200px) {}
