.raitings-section {
    display: grid;
    border-top: solid 10px #FF9900;
    grid-template-columns: auto;
    grid-template-rows: auto;
    align-content: center;
    justify-content: stretch;

    .ratings-cards {
        display: grid;
        padding: 30px;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: auto;
        column-gap: 20px;
        align-content: center;
        justify-content: stretch;

        .rating-card {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto auto auto;
            align-content: center;
            justify-content: center;

            .rating-card-capterra-image {
                width: 100%;
            }

            .rating-card-image-container {
                text-align: center;
            }

            .rating-card-apple-app-store-image-container {
                padding: 5px;
            }

            .rating-card-google-play-image {
                height: 100%;
            }

            .rating-card-apple-app-store-image {
                width: 100%;
            }

            .rating-card-star-image {
                height: 50px;
            }

            .rating-card-text {
                text-align: center;
            }
        }
    }
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {

}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    .raitings-section {
        display: grid;
        border-top: solid 10px #FF9900;
        grid-template-columns: auto;
        grid-template-rows: auto;
        align-content: center;
        justify-content: center;

        .ratings-cards {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto auto auto;
            grid-row-gap: 50px;
            align-content: center;
            justify-content: center;

            .rating-card {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto auto auto;
                align-content: center;
                justify-content: center;

                .rating-card-capterra-image {
                    width:80%;
                }
            }
        }
    }
}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1200px) {}

/* Special Laptops devices (laptops/desktops, 1200px and up) */
@media (min-width: 1300px) and (max-width: 1440px) {
    .raitings-section {
        display: grid;
        border-top: solid 10px #FF9900;
        grid-template-columns: auto;
        grid-template-rows: auto;
        align-content: center;
        justify-content: stretch;

        .ratings-cards {
            display: grid;
            padding: 30px;
            grid-template-columns: 33% 33% 33%;
            grid-template-rows: auto;
            column-gap: 20px;
            align-content: center;
            justify-content: stretch;

            .rating-card {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto auto auto;
                align-content: center;
                justify-content: center;

                .rating-card-capterra-image {
                    width: 80%;
                }

                .rating-card-image-container {
                    text-align: center;
                }

                .rating-card-image {
                    width: 100%;
                }

                .rating-card-star-image {
                    height: 50px;
                }

                .rating-card-text {
                    text-align: center;
                }
            }
        }
    }
}
