// Default Style
.ezclocker-logo-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-column-gap: 0;
    grid-row-gap: 0;
    align-content: center;
    justify-content: left;

    .ez-header-logo-link {
        text-decoration: none;
        cursor: pointer;
    }

    .ezclocker-logo {
        width: 75%;
    }
}


/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1200px) {}
