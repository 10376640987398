.main-features-section {
    display: grid;
    padding: 30px;
    grid-template-columns: auto;
    grid-template-rows: auto;
    align-content: start;
    justify-content: center;
    border-top: solid 10px #FF9900;
    background-color: var(--ezClockerWhite);

    .main-feature-card {
        display: grid;
        padding: 30px;
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        column-gap: 30px;
        align-content: start;
        justify-content: space-between;

        .clock-in-out-image-container {
            display: none;
        }

        .gps-verification-image-container {
            display:none;
        }

        .online-scheduling-image-container {
            display: none;
        }

        .main-feature-card-content {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto auto auto;
            align-content: start;
            justify-content: start;

            .main-feature-card-title {
                font-weight: bold;
            }

            .main-feature-card-details {
                font-size: 20pt;
            }

            .main-feature-learn-more-button {
                width: 200px;
                font-weight: bold;
                font-size: 16pt;
                color: var(--ezClockerBlack);
                background-color: var(--ezClockerOrange);
                border-color: var(--ezBorderOrange);
                cursor: pointer;
            }
        }

        .main-feature-card-image-container {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            column-gap: 0;
            grid-row-gap: 0;
            align-content: start;
            justify-content: start;

            .main-feature-card-image {
                width: 100%;
                background-color: var(--ezClockerOrange);
                border-color: var(--ezClockerOrange);
                border-width: 1px;
                border-style: solid;
                border-radius: 3px;
            }
        }

        .left-orange-bar {
            padding: 0;
            padding-left: 20px;
        }

        .right-orange-bar {
            padding: 0;
            padding-right: 20px;
        }
    }
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {
    .main-features-section {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-row-gap: 30px;
        align-content: start;
        justify-content: start;

        .main-feature-card {
            padding: 0px;
            column-gap: 0;
            grid-row-gap: 0;
            grid-template-columns: auto;
            grid-template-rows: auto auto;
            align-content: center;
            justify-content: start;

            .clock-in-out-image-container {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: start;

                .clock-in-out-image {
                    width: 100%;
                    background-color: var(--ezClockerOrange);
                    border-color: var(--ezClockerOrange);
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 3px;
                }
            }

            .gps-verification-image-container {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: start;

                .gps-verification-image {
                    width: 100%;
                    background-color: var(--ezClockerOrange);
                    border-color: var(--ezClockerOrange);
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 3px;
                }
            }

            .online-scheduling-image-container {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: start;

                .online-scheduling-image {
                    width: 100%;
                    background-color: var(--ezClockerOrange);
                    border-color: var(--ezClockerOrange);
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 3px;
                }
            }

            .main-feature-card-content {

                .main-feature-card-title {
                    margin-bottom: 8px;
                    font-size: 20pt;
                }

                .main-feature-card-details {
                    font-size: 16pt;
                    margin-bottom: 10px;

                    p {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }

            .main-feature-card-image-container {
                display: none;
            }
        }
    }
}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    .main-features-section {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-row-gap: 30px;
        align-content: start;
        justify-content: start;

        .main-feature-card {
            padding: 0px;
            column-gap: 0;
            grid-row-gap: 0;
            grid-template-columns: auto;
            grid-template-rows: auto auto;
            align-content: center;
            justify-content: start;

            .clock-in-out-image-container {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: start;

                .clock-in-out-image {
                    width: 100%;
                    background-color: var(--ezClockerOrange);
                    border-color: var(--ezClockerOrange);
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 3px;
                }
            }

            .gps-verification-image-container {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: start;

                .gps-verification-image {
                    width: 100%;
                    background-color: var(--ezClockerOrange);
                    border-color: var(--ezClockerOrange);
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 3px;
                }
            }

            .online-scheduling-image-container {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: start;

                .online-scheduling-image {
                    width: 100%;
                    background-color: var(--ezClockerOrange);
                    border-color: var(--ezClockerOrange);
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 3px;
                }
            }

            .main-feature-card-content {

                .main-feature-card-title {
                    margin-bottom: 8px;
                    font-size: 20pt;
                }

                .main-feature-card-details {
                    font-size: 16pt;
                    margin-bottom: 10px;

                    p {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }

            .main-feature-card-image-container {
                display: none;
            }

            .left-orange-bar {
                padding-left: 10px;
            }

            .right-orange-bar {
                padding-right: 10px;
            }
        }
    }
}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {
    .main-features-section {
        .main-feature-card {
            .left-orange-bar {
                padding-left: 10px;
            }

            .right-orange-bar {
                padding-right: 10px;
            }
        }
    }
}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {
    .main-features-section {
        .main-feature-card {
            .left-orange-bar {
                padding-left: 10px;
            }

            .right-orange-bar {
                padding-right: 10px;
            }
        }
    }
}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {
    .main-features-section {
        .main-feature-card {
            .left-orange-bar {
                padding-left: 10px;
            }

            .right-orange-bar {
                padding-right: 10px;
            }
        }
    }
}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1024px) and (max-width:1200px) {}

/* Special Laptops devices (laptops/desktops, 1200px and up) */
@media (min-width: 1300px) and (max-width: 1440px) {}

