/* Default Styling */
.mobile-navigation {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    align-content: center;
    justify-content: end;
}

.mobile-navigation-button {
    z-index: 99999;
    padding: 20px;
    cursor: pointer;
    font-size: 16pt;
    font-weight: bold;
    background-color: var(--ezClockerOrange);
    color: var(--ezClockerBlack);
    border-color: var(--ezBorderOrange);
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
}

.mobile-navigation-features-button {
    background-color: var(--ezClockerOrange);
    color: var(--ezClockerBlack);
    border-style: none;
}

.navigation-menu-item-link {
    font-size: 12pt;
    padding: 8px;
    width: 100%;
    color: var(--ezClockerBlack);
    text-decoration: none;
}
.navigation-menu-item-link:hover {
    background-color: var(--ezClockerNavy);
    color: var(--ezClockerWhite);
}
.navigation-menu-item-link:active {
    background-color: var(--ezClockerNavy);
    color: var(--ezClockerWhite);
}

.features-menu-title {
    margin-left: 20px;
    font-weight: bold;
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    .mobile-navigation-button {
        padding: 8px;
        cursor: pointer;
        font-size: 16pt;
        font-weight: bold;
        background-color: var(--ezClockerOrange);
        color: var(--ezClockerBlack);
        border-radius: 3px;
    }
}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1024px) and (max-width:1200px) {
}
