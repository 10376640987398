#ezcFooter {
    background-color: var(--ezClockerNavy);

    .footer-links-section {
        justify-content: center;
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        width: auto;
        padding-top: 40px;

        .responsive {
            display: none;
        }

        .non-responsive {
            display: flex;

            li {
                text-align: center;
            }
        }

        ul {
            padding-left: unset;
            list-style: none;
        }

        li {
            padding-top: 10px;
        }

        .social-networks,
        .apple-store-social-networks,
        .google-play-social-networks {
            margin: 55px 15px;
            cursor: pointer;
        }

        .facebook,
        .google,
        .twitter {
            font-size: 32pt;
            padding: 10px;
            transition: 0.2s;
            text-decoration: none;
        }

        .already-have-an-account {
            margin-top: 10px;
            font-size: 14pt;
        }

        a {
            text-decoration: none;
            font-weight: normal;
        }

        .appleAppStoreBadge {
            height: 50px;
        }

        .contact-us-button-container {
            margin-top: 20px;
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            align-content: center;
            justify-content: center;
        }

        .contact-us-button {
            background-color: var(--ezClockerOrange);
            color: var(--ezBlack);
            font-weight: normal;
            font-size: 16pt;
            cursor: pointer;
        }

        .contact-us-button:hover {
            background-color: var(--ezHoverOrange);
        }

        .contact-us-button:active {
            background-color: var(--ezDarkOrange);
        }

        .contact-us-button:disabled {
            background-color: var(--ezBorderOrange);
            border-color: var(--ezDarkOrange);
            color: var(--ezClockerOrange);
        }
    }

    .footer-copyright {
        padding-top: 3px;
        padding-bottom: 3px;
        background-color: var(--ezClockerGray);
        color: var(--ezClockerWhite);
        text-align: center;
    }

    .footer-link {
        color: var(--ezClockerWhite);
    }

    .footer-links{
        color: var(--ezClockerWhite);
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 500 !important;
        font-size: 0.875rem;
        line-height: 2.5;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        padding: 6px 8px;
    }
}

.browserInfoLink {
    text-decoration: none;
    color: var(--ezClockerStone);
    font-size: x-small;
}
.browserInfoLink:hover {
    text-decoration: none;
    color: var(--ezClockerWhite);
    font-size: x-small;
}
.browserInfoLink:active {
    text-decoration: none;
    color: var(--ezClockerOffWhite);
    font-size: x-small;
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {
    .meet-our-customers-main-div {
        .customerImageCarousel4 {
            display: none;
        }

        .customerImageCarousel1 {
            display: block;
        }
    }
}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    #ezcFooter {
        .footer-links-section {
            display: block;
            flex: 1;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            text-align: center;

            .footer-copyright,
            img {
                display: block;
                width: 97%;
                text-align: center;
                margin: 0;
                padding: 5px 0;
            }

            p {
                display: block;
                inline-size: unset;
                padding-left: 0;
                margin-left: 0;
                padding-right: 0;
                margin-right: 0;
                text-align: center;
            }

            div,
            .social-networks,
            .apple-store-social-networks,
            .google-play-social-networks {
                display: block;
                flex: 1;
                width: 100%;
                margin: 2px 0;
                padding: 4px 0;
                text-align: center;
            }

            .apple-store-social-networks,
            .google-play-social-networks {
                display: flex;
                flex-direction: column;
                flex: 1;
                align-items: center;

                img {
                    width: 135px;
                }
            }

            .social-networks {
                margin-top: 20px;
            }

            .non-responsive {
                display: none;
            }

            .responsive {
                display: block;
            }

            .ezclocker-dropdown-show,
            .product-dropdown-show,
            .industries-dropdown-show {
                display: block;
            }

            .ezclocker-dropdown-hide,
            .product-dropdown-hide,
            .industries-dropdown-hide {
                display: none;
            }

            .responsive-links-button {
                display: block;

            }

            .faChevron {
                padding-left: 10px;
            }

            .footer-responsive-main-link {
                display: flex;
                justify-content: center;
                align-items: baseline;
                padding-top: 15px;
            }

            .appleAppStoreBadge {
                height: unset;
            }
        }
    }
}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1200px) {}
