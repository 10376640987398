.customer-image-carousel {
    .customer-images-container-slider {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        align-content: center;
        justify-content: space-evenly;

        .meet-our-customers-chevron {
            display: block;
            padding-top: 130px;
            font-size: 50pt;
            cursor: pointer;
        }

        .meet-our-customers-image {
            height: 300px;
            border-radius: 50%;
        }

        .meet-our-customers-image-div {
            margin: 10px;
            width: 100%;
        }

        .slide {
            display: none;
        }
    }
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    .customer-image-carousel {
        .customer-images-container-slider {
            display: grid;
            grid-template-columns: auto auto auto auto;
            align-content: center;
            justify-content: space-evenly;

            .meet-our-customers-chevron {
                display: block;
                padding-top: 75px;
                font-size: 50pt;
                cursor: pointer;
            }

            .meet-our-customers-image {
                height: 200px;
                border-radius: 50%;
            }

            .meet-our-customers-image-div {
                margin: 10px;
                width: 100%;
            }

            .slide {
                display: none;
            }
        }
    }

}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {
    .customer-image-carousel {
        .customer-images-container-slider {
            display: grid;
            grid-template-columns: auto auto auto auto;
            align-content: center;
            justify-content: space-evenly;

            .meet-our-customers-chevron {
                display: block;
                padding-top: 125px;
                font-size: 50pt;
                cursor: pointer;
            }

            .meet-our-customers-image {
                height: 300px;
                border-radius: 50%;
            }

            .meet-our-customers-image-div {
                margin: 10px;
                width: 100%;
            }

            .slide {
                display: none;
            }
        }
    }
}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {
    .customer-image-carousel {
        .customer-images-container-slider {
            display: grid;
            grid-template-columns: auto auto auto auto;
            align-content: center;
            justify-content: space-evenly;

            .meet-our-customers-chevron {
                display: block;
                padding-top: 125px;
                font-size: 50pt;
                cursor: pointer;
            }

            .meet-our-customers-image {
                height: 300px;
                border-radius: 50%;
            }

            .meet-our-customers-image-div {
                margin: 10px;
                width: 100%;
            }

            .slide {
                display: none;
            }
        }
    }
}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1024px) and (max-width:1200px) {}

/* Special Laptops devices (laptops/desktops, 1200px and up) */
@media (min-width: 1300px) and (max-width: 1440px) {
    .customer-image-carousel {
        .customer-images-container-slider {
            display: grid;
            grid-template-columns: auto auto auto auto auto auto;
            align-content: center;
            justify-content: space-evenly;

            .meet-our-customers-chevron {
                display: block;
                padding-top: 85px;
                font-size: 50pt;
                cursor: pointer;
            }

            .meet-our-customers-image {
                height: 200px;
                border-radius: 50%;
            }

            .meet-our-customers-image-div {
                margin: 10px;
                width: 100%;
            }

            .slide {
                display: none;
            }
        }
    }
}
