.color-blue {
    color: #005b96;
}

.color-white {
    color: #ffffff !important;
}

.color-orange {
    color: #FF9900;
}

.color-nav-duller-white {
    color: #D0CBBC;
}

.color-light-blue {
    color: #61dafb;
}

.color-dull-white {
    color: #e0e0e0 !important;
}

.color-links {
    color: #61dafb;
}

.color-black {
    color: #000000;
}

.color-gray-white {
    color: #cccccc;
}

.color-dark-dull-blue {
    color: #03396c;
}

.color-e1e1e1-white {
    color: #E1E1E1 !important;
}

.background-color-e1e1e1-white {
    background-color: #E1E1E1;
}

.background-color-dark-dull-blue {
    background-color: #03396c;
}

.background-color-gray-white {
    background-color: #cccccc;
}

.background-color-black {
    background-color: #000000;
}

.background-color-blue {
    background-color: #005b96;
}

.background-color-white {
    background-color: #ffffff;
}

.background-color-orange {
    background-color: #FF9900;
}

.background-color-light-blue {
    background-color: #61dafb;
}

.background-color-dull-white {
    background-color: #e0e0e0;
}

.background-color-links {
    background-color: #61dafb;
}

.background-color-nav-duller-white {
    background-color: #D0CBBC;
}

.background-color-transparent {
    background-color: transparent;
}

.border-color-gray {
    border-color: #6d6e70;
}
