.try-for-free-section {
    display: grid;
    padding: 30px;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    align-content: center;
    justify-content: start;
    background-color: var(--ezClockerNavy);

    .try-for-free-content {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-row-gap: 20px;
        align-content: start;
        justify-content: start;

        .try-for-free-title {
            font-size: 38pt;
            font-weight: bold;
            color: var(--ezClockerWhite);
        }

        .try-for-free-input-container {
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto;
            column-gap: 20px;
            grid-row-gap: 20px;
            align-content: start;
            justify-content: stretch;

            .try-for-free-email-input {
                padding: 8px;
                background-color: var(--ezClockerWhite);
                font-size: 16px;
                border-style: solid;
                border-width: 1px;
                border-color: var(--ezClockerGray);
            }

            .try-for-free-button {
                width: 200px;
                background-color: var(--ezClockerOrange);
                color: var(--ezClockerBlack);
                font-weight: bold;
                cursor: pointer;
            }

            .try-for-free-mobile-button {
                display: none;
                background-color: var(--ezClockerOrange);
                color: var(--ezClockerBlack);
                font-weight: bold;
                cursor: pointer;
            }
        }

        .already-have-an-account-text {
            color: var(--ezClockerWhite);
            font-size: 14px;
            font-weight: normal;
        }

        .already-have-an-account-link {
            color: var(--ezClockerWhite);
            text-decoration: none;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .try-for-free-image-container {
        padding: 20px;
        display: grid;
        grid-template-columns: audo;
        grid-template-rows: auto;
        align-content: start;
        justify-content: start;

        .try-for-free-employee-dashboard-image {
            width: 100%;
        }
    }
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {
    .try-for-free-section {

        .try-for-free-content {

            .try-for-free-input-container {

                .try-for-free-email-input {
                    display: none;
                }

                .try-for-free-button {
                    display: none;
                }

                .try-for-free-mobile-button {
                    display: initial;
                }
            }
        }
    }

}

/* X-Small devices between 320px and 479.98px */
@media (min-width : 320px) and (max-width: 479.98px) {
    .try-for-free-section {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;

        .try-for-free-content {

            .try-for-free-title {
                font-size: 20pt;
            }

            .try-for-free-input-container {
                grid-template-columns: auto;
                grid-template-rows: auto auto;

                .try-for-free-email-input {
                    display: none;
                }

                .try-for-free-button {
                    display: none;
                }

                .try-for-free-mobile-button {
                    display: initial;
                }
            }
        }
    }
}

/* Small devices between 480px and 767.98px */
@media (min-width: 480px) and (max-width: 767.98) {
    .try-for-free-section {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;

        .try-for-free-content {

            .try-for-free-title {
                font-size: 20pt;
            }

            .try-for-free-input-container {
                grid-template-columns: auto;
                grid-template-rows: auto auto;

                .try-for-free-email-input {
                    display: none;
                }

                .try-for-free-button {
                    display: none;
                }

                .try-for-free-mobile-button {
                    display: initial;
                }
            }
        }
    }
}

/* Medium devices between 768px and 991.98 */
@media (min-width: 768px) and (max-width: 991.98px) {
    .try-for-free-section {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;

        .try-for-free-content {
            grid-template-rows: auto auto;
        }
    }
}

/* Large devices between 992 and 1199.98 */
@media (min-width:992px) and (max-width: 1199.98px) {
    .try-for-free-section {
        padding: 20px;

        .try-for-free-content {

            .try-for-free-title {
                font-size: 28pt;
            }
        }
    }
}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1024px) and (max-width:1200px) {
    .try-for-free-section {

        .try-for-free-content {

            .try-for-free-title {
                font-size: 28pt;
            }
        }
    }
}

/* Special Laptops devices (laptops/desktops, 1200px and up) */
@media (min-width: 1300px) and (max-width: 1440px) {
    .try-for-free-section {

        .try-for-free-content {

            .try-for-free-title {
                font-size: 28pt;
            }
        }
    }
}
