@import '../../assets/styles/ez-responsive';

.privacyPage {
    .privacyBannerImage {
        max-height: 300px;
        width: 100%;
        height: auto;
    }

    .ezClockerBasicContentContainer {
        background-color: rgba(255, 255, 255, 0.5);
        margin: 0;
        padding: 20px;
        border-style: none;
        border-width: 0;
        border-top-color: #000000;
        border-top-width: 1px;
        border-top-style: solid;
        box-shadow: 0 -2px 4px 0 rgba(109, 110, 112, 0.5);
    }

    .tosHeading {
        /* color: var(--ezClockerNavy); */
        color: #0f4777;
        font-size: 18pt;
        font-weight: bold;
        margin-top: 2px;
        margin-bottom: 12px;
    }

    .tosSubHeading {
        color: #000000;
        font-size: 16pt;
        font-weight: bold;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    li {
        line-height: normal;
    }

    p {
        line-height: normal;
        margin-top: revert;
    }
}

/*------------------------------------------------------
| @Media for Regular Mobile Devices
--------------------------------------------------------*/
@media only screen and (min-width: $ezMobileMinWidth) and (max-width: $ezMobileMaxWidth) {
    .privacyPage {
        .bannerBackground {
            height: 300px;
        }
    }
}