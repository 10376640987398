:root {
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    | ezClocker Primary Color Variables
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    --ezClockerOrange: #ff9900;
    --ezClockerNavy: #0f4777;
    --ezClockerLightNavy: #18699b;
    --ezClockerAqua: #316dac;
    --ezClockerYellow: #ffc500;
    --ezClockerGray: #6d6e70;
    --ezClockerStone: #c0c0c0;
    --ezClockerOffWhite: #f0f0f0;
    --ezClockerBlue: #2419b2;
    --ezClockerSilver: #e0e0e0;
    --ezClockerActionGreen: #8df13c;
    --ezClockerAlertRed: #FF0700;
    --ezClockerBabyBlue: #7CD0FD;
    --ezClockerBlack: #000000;
    --ezClockerWhite: #ffffff;
    --ezClockerBackground: #E1E1E1;
    --ezClockerGreen: #00B945;
    --ezClockerWarningYellow: #ffff00;
    --ezClockerSeaMistGreen: #00AA72;

    /* Highlighting Colors */
    --ezClockerInfoYellow: #FFEEAA;
    --ezClockerErrorRed: #ffcccc;
    --ezClockerInputErrorBackground: #ffeeee;
    --ezClockerHotGreen: #cbff00;
    --ezClockerTeal: #00B1EC;
    --ezBreakInTeal: #81DDFB;
    --ezClockerWarnning: #9b0400;

    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    | ezClocker Color Alternative Colors and Shades
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    --ezClockerBackground-alpha: rgba(225, 225, 225, 0.95);

    /* Time Entry Table Cell Background Colors */
    --ezTimeEntryNormalBackgroundColor: var(--ezClockerWhite);
    --ezTimeEntryActiveClockInBackgroundColor: rgb(202 255 158 / 50%);
    --ezTimeEntryActiveClockInBorderColor: var(--ezClockerActionGreen);
    --ezTimeEntryErrorBackgroundColor: rgb(255 192 192 / 30%);
    --ezTimeEntryWarninggroundColor: rgb(255 230 151 / 20%);
    --ezTimeEntryPartialgroundColor: rgb(92 230 221 / 20%);
    --ezTimeEntryBreakBackgroundColor: rgb(224 224 224 / 50%);

    --ezTimeEntryActiveBreakBorderColor: var(--ezClockerTeal);

    /* ezClockerActionGreen #8df13c Shades */
    --ezActionGreen: #8df13c;
    --ezActionGreenHoverColor: #caff9e;
    --ezActionGreenBorderColor: #439400;

    /* ezClockerBlack 000000 Shades */
    --ezFocusBoxShadowBlack: 0 0 1px 1px rgb(0 0 0 / 30%);

    /* ezClockerOffWhite f0f0f0 Shades */
    --ezOffWhite: var(--ezClockerOffWhite);
    --ezHoverOffWhite: #edf6ff;
    --ezDarkOffWhite: #c0c0c0;
    --ezBorderOffWhite: var(--ezClockerBlack);

    /* ezClockerSeaMistGreen */
    --ezClockerSeaMistLightGreen: #4BBF99;
    --ezClockerSeaMistHoverGreen: #27AF83;
    --ezClockerSeaMistDarkGreen: #008458;
    --ezClockerSeaMistBorderGreen: #006746;

    /* ezClockerGreen 00B945 Shades: http://paletton.com/#uid=12X0u0kw0w0jvDaoOy4y4oODajv */
    --ezClockerLightGreen: #4FCB7D;
    --ezClockerHoverGreen: #2BBE61;
    --ezClockerDarkGreen: #008F35;
    --ezClockerBorderGreen: #00712A;
    --ezClockerGreenContainerText: var(--ezClockerBlack);

    /* ezClockerTeal #00AEEC Shades: Palette URL: http://paletton.com/#uid=13p0u0k++UDrWZSFj+V+Vxi+0pA */
    --ezTeal: var(--ezClockerTeal);
    --ezTealHover: #28C7FC;
    --ezTealActive: #06C1FF;
    --ezDarkteal: #007BA3;
    --ezTealBorder: #00607F;
    --ezTealContainerTextColor: var(--ezClockerBlack);
    --ezTealContainerDisabledTextColor: var(--ezTealBorder);

    /* ezClockerHotGreen #cbff00 Shades: Palette URL: http://paletton.com/#uid=12a0u0kw0++jQ+npT+WSD+VZDDY */
    --ezHotGreen: var(--ezClockerHotGreen);
    --ezLightHotGreen: #dfff61;
    --ezHoverHotGreen: #d5ff31;
    --ezDarkHotGreen: #cbff00;
    --ezBorderHotGreen: #c5f800;
    --ezHotGreenContainerFontColor: var(--ezClockerBlack);

    /* ezClockerOrange #ff9900 Shades: Palette URL: http://paletton.com/#uid=10M0u0kw0w0jvDaoOy4y4oODajv */
    --ezOrange: var(--ezClockerOrange);
    --ezDimOrange: rgb(255 153 0 / 50%);
    --ezLightOrange: #FFC164;
    --ezHoverOrange: #FFB039;
    --ezDarkOrange: #C67600;
    --ezBorderOrange: #9B5D00;

    /* ezClockerNavy #0f4777 Shades: Palette URL: http://paletton.com/#uid=13y0u0ku0n9lhu9rus-z8iTIPdA */
    --ezNavy: var(--ezClockerNavy);
    --ezLightNavy: #3B6F9B;
    --ezHoverNavy: #1D5D93;
    --ezDarkNavy: #063861;
    --ezDarkNavyAlpha: rgb(6 56 97 / 50%);
    --ezBorderNavy: #032746;
    --ezNavyContainerTextColor: var(--ezClockerWhite);
    --ezNavyContainerDisabledTextColor: var(--ezBorderNavy);
    --ezNavyInsetBoxShadow: inset 0 0 4px 2px rgb(6 56 97 / 50%);

    /* ezClockerAqua #316dac Shades: Palette URL: http://paletton.com/#uid=13A0u0koMyaeSLXkeDNvkvpvLn- */
    --ezAqua: var(--ezClockerAqua);
    --ezLightAqua: #7AA7D5;
    --ezHoverAqua: #4F85BE;
    --ezDarkAqua: #1058A3;
    --ezBorderAqua: #0A417A;

    /* ezClockerYellow #ffc500 Shades: Palette URL: http://paletton.com/#uid=11f0u0kw0w0jvDaoOy4y4oODajv */
    --ezYellow: var(--ezClockerYellow);
    --ezExtraLightYellow: #FFF564;
    --ezLightYellow: #FFDB64;
    --ezHoverYellow: #FFD239;
    --ezDarkYellow: #C69800;
    --ezBoxShadowDarkYellow: inset 0 0 4px 2px rgb(198 152 0 / 50%);
    --ezBorderYellow: #9B7800;
    --ezYelloContainerText: #000000;

    /* ezClockerGray #6d6e70 Shades: Palette URL: http://paletton.com/#uid=13J0u0k0Yl60jzL0Cqw1Dg039ap */
    --ezGray: var(--ezClockerGray);
    --ezLightGray: #B2B3B4;
    --ezHoverGray: #8A8B8D;
    --ezDarkGray: #515255;
    --ezBorderGray: #323437;
    --ezGrayContainerText: var(--ezClockerWhite);
    --ezGrayBoxShaddow: 0 0 1px 1px rgb(109 110 112 / 30%);

    /* ezClockerBlue #2419b2 Shades: Palette URL: http://paletton.com/#uid=1430u0kvXwbjvDmoOyiyaoUDaj- */
    --ezBlue: var(--ezClockerGray);
    --ezLightBlue: #665EC6;
    --ezHoverBlue: #463DB7;
    --ezDarkBlue: #1B128A;
    --ezBorderBlue: #120B6D;

    /* ezClockerSilver #e0e0e0 Shades: Palette URL: http://paletton.com/#uid=1000u0k00s700++00Da01mx0lgX */

    /* NOTE: Altered slightly from palette */
    --ezSilver: var(--ezClockerSilver);
    --ezLightSilver: #f0f0f0;
    --ezHoverSilver: var(--ezClockerWhite);

    /* rgba(179, 179, 179) */
    --ezDarkSilver: #B3B3B3;
    --ezBorderSilver: #878686;
    --ezAlphaSilver: rgb(224 224 224 / 50%);

    /* ezClockerStone #c0c0c0 Shades: Palette: https://paletton.com/#uid=1000u0k00o601Ma00tU01iH0qcO */
    --ezStone: #c0c0c0;
    --ezHoverStone: #eeeeee;
    --ezDarkStone: #959595;
    --ezBorderStone: #666565;
    --ezStoneContainerText: var(--ezClockerBlack);
    --ezStoneDisabledText: var(--ezBorderStone);
    --ezBoxShadowStone: 0 0 1px 1px rgb(109 110 112 / 30%);

    /* ezClockerActionGreen #8EF13C Shades: Palette URL: http://paletton.com/#uid=12A0u0ko2KWdnU9jcPqt5GhBEvn */
    --ezActionGreen: var(--ezClockerActionGreen);
    --ezLightActionGreen: #C0F991;
    --ezHoverActionGreen: #A5F562;
    --ezDarkActionGreen: #77ED16;
    --ezBorderActionGreen: #65DF00;
    --ezActionGreenContainerText: var(--ezClockerBlack);

    /* ezClockerErrorRed #ffcccc Shades: Palette URL: http://paletton.com/#uid=1000u0k6qw00RTG38I4ayrtfymW */
    --ezErrorRed: var(--ezClockerErrorRed);
    --ezLightErrorRed: #fff8f8;
    --ezHoverErrorRed: #ffe6e6;
    --ezDarkErrorRed: #db9393;
    --ezBorderErrorRed: #b75e5e;

    /* ezClockerAlertRed #FF0700 Shades: Palette URL: http://paletton.com/#uid=1010u0kw0w0jvDaoOy4y4oODajv */
    --ezAlertRed: var(--ezClockerAlertRed);
    --ezSoftAlertRed: var(--ezClockerErrorRed);
    --ezLightAlertRed: #ff6864;
    --ezHoverAlertRed: #ff3e39;
    --ezDarkAlertRed: #c60500;
    --ezBorderAlertRed: #9b0400;
    --ezAlertText: var(--ezClockerWhite);

    /* ezClockerWarningYellow #ffff00 Shades: https://paletton.com/#uid=11T0u0kw0w0jvDaoOy4y4oODajv */
    --ezWarningYellow: var(--ezClockerWarningYellow);
    --ezLightWarningYellow: #FFFFC0;
    --ezHoverWarningYellow: #FFFF39;
    --ezDarWarningYellow: #FFFF39;
    --ezBorderWarningYellow: #9B9B00;
    --ezClockerInputWarningBackground: rgb(255 255 0 / 50%);

    /* ezClockerBabyBlue #7CD0FD Shades: Palette URL: http://paletton.com/#uid=13t0u0khe+j35+Xbf+EmsZnrCWa */
    --ezBabyBlue: var(--ezClockerBabyBlue);
    --ezLightBabyBlue: #e8f7ff;
    --ezHoverBabyBlue: #a9e0fe;
    --ezDarkBabyBlue: #54c0Fa;
    --ezBorderBabyBlue: #2cacf1;

    /* ezClockerBlack #000000 Shades: Palette URL: http://paletton.com/#uid=1000u0k00000nfW028N8N02fW0n */
    --ezBlack: #000000;
    --ezLightBlack: #7F7E7E;
    --ezHoverBlack: #464646;
    --ezBorderBlack: #030101;

    /* ezClockerWhite #ffffff Shades: Palette URL: http://paletton.com/#uid=1000u0k00w000++00SJ00pV0hkj */
    --ezWhite: var(--ezClockerWhite);
    --ezDarkWhite: #CECECE;
    --ezBorderWhite: #A2A0A0;
    --ezWhiteContainerText: var(--ezClockerBlack);

    /* === ezClocker Alpha Colors === */

    /* Alpha Gray #1 from var(--ezClockerGray), #6d6e70 */
    --ezAlphaEzClockerGray1: rgb(109 110 112 / 30%);

    /* Alpha Navy #1 from var(--ezClockerNavy), #0f4777 */
    --ezAlphaEzClockerNavy1: rgb(15 71 119 / 30%);

    /* baby blue, darkened, near fully transparent */
    --ezAlphaGlass: rgb(0 75 255 / 10%);


    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    | ezClocker Background Colors
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* === ezClocker Background Colors === */
    --ezLightBackgroundWhite: #ffffff;
    --ezAlphaBackgroundWhite1: rgb(225 225 225 / 30%);
    --ezAlphaBackgroundWhite2: rgb(255 255 255 / 40%);
    --ezAlphaBackgroundWhite3: rgb(255 255 255 / 90%);
    --ezBackgroundWhite: var(--ezClockerBackground);
    --ezDarkAlphaBackgroundWhite: rgb(180 180 180 / 30%);
    --ezDarkBackgroundWhite: #B4B4B4;
    --ezBorderAlphaBackgroundWhite: rgb(136 135 135 / 30%);
    --ezBorderBackgroundWhite: #888787;
    --ezBackgroundGradientAlphaWhite1: rgb(255 255 255 / 7%);
    --ezBackgroundGradientAlphaWhite2: rgb(255 255 255 / 13%);
    --ezBackgroundGradientAlphaWhite3: rgb(255 255 255 / 17%);
    --ezBackgroundGradientAlphaWhite4: rgb(255 255 255 / 19%);
}