.cpPage {
    .cpBannerImage {
        max-height: 300px;
        width: 100%;
        height: auto;
    }

    .bannerBackground {
        height: 300px;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: left;
    }

    .ezBannerContainer {
        position: relative;
        width: 100%;
    }

    .ezClockerBasicContentContainer {
        background-color: rgba(255, 255, 255, 0.5);
        margin: 0;
        padding: 20px;
        border-style: none;
        border-width: 0;
        border-top-color: #000000;
        border-top-width: 1px;
        border-top-style: solid;
        box-shadow: 0 -2px 4px 0 rgba(109, 110, 112, 0.5);
    }

    .cpHeading {
        color: #0f4777;
        font-size: 18pt;
        font-weight: bold;
        margin-top: 2px;
        margin-bottom: 12px;
    }

    p {
        line-height: normal;
    }
}