.margin-30 {
    margin: 30px;
}

.margin-left-30 {
    margin-left: 30px;
}

.margin-top-70 {
    margin-top: 70px;
}

@media screen and (max-width: 768px) {
    .margin-30 {
        margin: 30px 0;
    }

    .margin-left-30 {
        margin-left: 0;
    }

    .margin-top-70 {
        margin-top: 0;
    }

}
